import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import { withViewport } from '../../util/uiHelpers';
import { useConfiguration } from '../../context/configurationContext';

import css from './LandingPage.module.css';

//Static assets
import facebookImage from '../../assets/biketribe-facebook-sharing-1200x630.jpg';
import twitterImage from '../../assets/biketribe-twitter-sharing-600x314.jpg';
import SectionHero from './SectionHero/SectionHero';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import HowItWorks from './Sections/HowItWorks';
import PopularMoorage from './Sections/PopularMoorage';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import InterestedInBecomingHost from './Sections/InterestedInBecomingHost';
import CustomerTestimonials from './Sections/CustomerTestimonials';
import SectionDestinations from './Sections/SectionDestinations';
import WhatMakesUsDifferent from './Sections/WhatMakesUsDifferent';

//Section pages

export const LandingPageComponent = props => {
  const { intl, scrollingDisabled, history, listings, viewport } = props;

  const config = useConfiguration();

  const siteTitle = config.marketplaceName;

  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({
    id: 'LandingPage.schemaDescription',
  });
  const schemaImage = `${config.marketplaceRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.marketplaceRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn
        className={css.pageRoot}
        topbar={<TopbarContainer />}
        footer={<FooterContainer />}
      >
        <div className={css.pageContent}>
          <SectionHero viewport={viewport} history={history} />
          <InterestedInBecomingHost />
          <SectionDestinations />
          <HowItWorks />
          <PopularMoorage listings={listings} />
          <WhatMakesUsDifferent />
          <CustomerTestimonials />
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { currentPageResultIds } = state.LandingPage;
  const listings = getListingsById(state, currentPageResultIds);

  return {
    listings,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({});

const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(LandingPageComponent);

export default LandingPage;
