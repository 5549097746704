import React from 'react';
import { useIntl } from '../../../util/reactIntl';
import safety from '../../../assets/safety.png';
import simplePricing from '../../../assets/Simple-pricing.png';
import offsetMoorageMaintenanceCosts from '../../../assets/Offset-moorage-maintenance-costs.png';
import scuba from '../../../assets/scuba.png';
import reef from '../../../assets/reef.png';
import communityDriven from '../../../assets/Community-driven.png';

import css from './Sections.module.css';

function WhatMakesUsDifferent() {
  const intl = useIntl();

  const title = intl.formatMessage({
    id: 'WhatMakeUsDifferent.title',
  });

  const sections = [
    {
      imgSrc: safety,
      titleId: 'WhatMakesUsDifferent.onBuoyHostProtection.title',
      descriptionId: 'WhatMakesUsDifferent.onBuoyHostProtection.description',
    },
    {
      imgSrc: scuba,
      titleId: 'WhatMakesUsDifferent.tieUpsYouCanTrust.title',
      descriptionId: 'WhatMakesUsDifferent.tieUpsYouCanTrust.description',
    },
    {
      imgSrc: simplePricing,
      titleId: 'WhatMakesUsDifferent.simplePricing.title',
      descriptionId: 'WhatMakesUsDifferent.simplePricing.description',
      extraTextId: 'WhatMakesUsDifferent.simplePricing.extraTextId',
    },
    {
      imgSrc: offsetMoorageMaintenanceCosts,
      titleId: 'WhatMakesUsDifferent.offsetMoorageMaintenanceCosts.title',
      descriptionId: 'WhatMakesUsDifferent.offsetMoorageMaintenanceCosts.description',
    },
    {
      imgSrc: reef,
      titleId: 'WhatMakesUsDifferent.environmentallyResponsible.title',
      descriptionId: 'WhatMakesUsDifferent.environmentallyResponsible.description',
    },
    {
      imgSrc: communityDriven,
      titleId: 'WhatMakesUsDifferent.communityDriven.title',
      descriptionId: 'WhatMakesUsDifferent.communityDriven.description',
    },
  ];

  return (
    <div className={css.makeDifferentSection}>
      <div className={css.contentWidth}>
        <h1>{title}</h1>
        <div className={css.makeDifferentContent}>
          {sections.map(({ imgSrc, titleId, descriptionId, extraTextId }) => (
            <div className={css.makeDifferentBlock} key={titleId}>
              <div className={css.makeDifferentImg}>
                <img src={imgSrc} alt="" />
              </div>
              <h2>{intl.formatMessage({ id: titleId })}</h2>
              <p>{intl.formatMessage({ id: descriptionId })}</p>
              <p>{extraTextId ? intl.formatMessage({ id: extraTextId }) : ""}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WhatMakesUsDifferent;
