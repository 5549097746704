import React from 'react';
import { useIntl } from '../../../util/reactIntl';
import findIcon from './SectionIcons/map.png';
import reserveIcon from './SectionIcons/calendar.png';
import relaxIcon from './SectionIcons/glasses.png';

import css from './Sections.module.css';

function HowItWorks() {
  const intl = useIntl();
  const sections = [
    {
      icon: findIcon,
      title: intl.formatMessage({
        id: 'HowItWorks.find',
      }),
      description: intl.formatMessage({
        id: 'HowItWorks.findDescription',
      }),
    },
    {
      icon: reserveIcon,
      title: intl.formatMessage({
        id: 'HowItWorks.reserve',
      }),
      description: intl.formatMessage({
        id: 'HowItWorks.reserveDescription',
      }),
    },
    {
      icon: relaxIcon,
      title: intl.formatMessage({
        id: 'HowItWorks.relax',
      }),
      description: intl.formatMessage({
        id: 'HowItWorks.relaxDescription',
      }),
    },
  ];

  return (
    <div className={css.moreAbout}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2>
              {intl.formatMessage({
                id: 'HowItWorks.title',
              })}
            </h2>
          </div>
        </div>
      </div>
      <div className={css.contentWidth}>
        {sections.map((section, index) => (
          <div className={css.moreInfoBlock} key={index}>
            <img src={section.icon} alt={section.title} />
            <h4>{section.title}</h4>
            <p>{section.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HowItWorks;
