import React from 'react';
import { useIntl } from '../../../util/reactIntl';
import { Icons } from '../../../components';
import Slider from 'react-slick';

import css from './Sections.module.css';

function RenderNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <Icons name="rightArrow" />
    </div>
  );
}

function RenderPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <Icons name="leftArrow" />
    </div>
  );
}

function CustomerTestimonials() {
  const intl = useIntl();
  const sliderSettings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3, // Display 4 items on desktop
    slidesToScroll: 1, // Scroll 1 item at a time
    variableWidth: false,
    focusOnSelect: false,
    centerMode: false,
    nextArrow: <RenderNextArrow />,
    prevArrow: <RenderPrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480, // Adjust settings for smaller viewports
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const testimonials = [
    {
      user: intl.formatMessage({
        id: 'CustomerTestimonials.first',
      }),
      description: intl.formatMessage({
        id: 'CustomerTestimonials.firstDescription',
      }),
    },
    {
      user: intl.formatMessage({
        id: 'CustomerTestimonials.second',
      }),
      description: intl.formatMessage({
        id: 'CustomerTestimonials.secondDescription',
      }),
    },
    {
      user: intl.formatMessage({
        id: 'CustomerTestimonials.third',
      }),
      description: intl.formatMessage({
        id: 'CustomerTestimonials.thirdDescription',
      }),
    },
  ];

  return (
    <div className={css.testimonialSection}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2>
              {intl.formatMessage({
                id: 'CustomerTestimonials.title',
              })}
            </h2>
          </div>
        </div>
        <div className={css.contentWidth}>
          <Slider {...sliderSettings}>
            {testimonials?.map((item, index) => (
              <div key={index} className={css.testimonialCard}>
                <p>{item?.description}</p>
                <div className={css.userinfo}>{item?.user}</div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default CustomerTestimonials;
