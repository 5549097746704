import React from 'react';
import { useIntl } from '../../../util/reactIntl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import css from './Sections.module.css';

import lopezIcon from './SectionIcons/lopez.jpg';
import marrowstoneIcon from './SectionIcons/marrowstone.jpg';
import suciaIcon from './SectionIcons/sucia.jpeg';
import bainbridgeIcon from './SectionIcons/bainbridge.jpg';

function SectionDestinations() {
  const intl = useIntl();
  const history = useHistory();

  const sections = [
    {
      icon: lopezIcon,
      title: intl.formatMessage({
        id: 'SectionDestinations.LopezIsland',
      }),
      search:
        's?address=Lopez%20Island%2C%20Washington%2C%20United%20States&bounds=48.581836%2C-122.773281786%2C48.396798432%2C-122.967539324',
    },
    {
      icon: marrowstoneIcon,
      title: intl.formatMessage({
        id: 'SectionDestinations.MarrowstoneIsland',
      }),
      search:
        's?address=Marrowstone%2C%20Washington%2C%20United%20States&bounds=48.103214%2C-122.669008%2C48.008584%2C-122.727057986',
    },
    {
      icon: suciaIcon,
      title: intl.formatMessage({
        id: 'SectionDestinations.SuciaIslands',
      }),
      search:
        's?address=Echo%20Bay%2C%20Eastsound%2C%20Washington%2C%20United%20States&bounds=48.78063930554143%2C-122.87452544144016%2C48.74470669445857%2C-122.92903655855984',
    },
    {
      icon: bainbridgeIcon,
      title: intl.formatMessage({
        id: 'SectionDestinations.BainbridgeIslands',
      }),
      search:
        's?address=Bainbridge%20Island%2C%20Washington%2C%20United%20States&bounds=47.7306907%2C-122.4524702%2C47.5575004%2C-122.603',
    },
  ];

  return (
    <div className={css.destinationSection}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2>
              {intl.formatMessage({
                id: 'SectionDestinations.title',
              })}
            </h2>
          </div>
        </div>
        <div className={css.destinationBlock}>
          {sections.map((section, index) => (
            <div
              className={css.moreInfoBlock}
              key={index}
              onClick={() => history.push(section?.search)}
            >
              <img src={section.icon} alt={section.title} />
              <div className={css.destinationContent}>
                {section.title && <h4>{section.title}</h4>}
                {section.description || (true && <p>{section.description}</p>)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SectionDestinations;
