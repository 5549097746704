import React, { useState } from 'react';
import { useIntl } from '../../../util/reactIntl';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import LandingSearchForm from './LandingSearchForm/LandingSearchForm';
import { Heading, NamedLink } from '../../../components';
import axios from 'axios';

import { types as sdkTypes } from '../../../util/sdkLoader';
import css from './SectionHero.module.css';

const { LatLng, LatLngBounds } = sdkTypes;

function SectionHero(props) {
  const { history, viewport } = props;

  const intl = useIntl();
  const routeConfiguration = useRouteConfiguration();
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const keywords = [
    {
      name: intl.formatMessage({
        id: 'SectionHero.mooringBuoy',
      }),
      search: 'pub_category=mooringBuoy',
    },
    {
      name: intl.formatMessage({
        id: 'SectionHero.privateDock',
      }),
      search: 'pub_category=privateDock',
    },
  ];

  const handleSubmit = async values => {
    setSubmitInProgress(true);
    const { location = {} } = values;
    const { search, selectedPlace = {} } = location;
    const { bounds = null } = selectedPlace;

    if (!location || !location?.selectedPlace) {
      try {
        // Fetching geolocation data from IPAPI
        const response = await axios.get('https://ipapi.co/json/');
        const { latitude, longitude, city } = response.data;

        // Determine the search radius
        const radius = 50; // 50 miles
        const radiusInDegrees = radius / 69; // Approximate conversion from miles to degrees

        const bounds = new LatLngBounds(
          new LatLng(latitude + radiusInDegrees, longitude + radiusInDegrees),
          new LatLng(latitude - radiusInDegrees, longitude - radiusInDegrees)
        );

        // Now handle the absence of location data
        const searchQuery = {
          address: search || city,
          bounds,
        };
        setSubmitInProgress(false);
        history.push(
          createResourceLocatorString('SearchPage', routeConfiguration, {}, searchQuery)
        );
      } catch (error) {
        console.error('Failed to fetch location data:', error);
      }
    } else {
      // If location data is present
      const searchQuery = {
        address: search,
        bounds,
        search,
      };
      setSubmitInProgress(false);
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchQuery));
    }
  };

  const title = intl.formatMessage({ id: 'SectionHero.title' });
  const description = intl.formatMessage({ id: 'SectionHero.description' });
  const viewAvailable = intl.formatMessage({ id: 'SectionHero.viewAvailable' });

  return (
    <div className={css.heroContainer}>
      <div className={css.contentWidth}>
        <div className={css.heroContent}>
          <Heading as="h1" rootClassName={css.heading}>
            {title}
          </Heading>
          <p>{description}</p>
          <div className={css.heroSearchSec}>
            <LandingSearchForm
              rootClassName={css.landingSearchForm}
              onSubmit={handleSubmit}
              submitInProgress={submitInProgress}
              intl={intl}
              viewport={viewport}
            />
          </div>
          <div className={css.bottomContent}>
            <div className={css.keywords}>
              <span className={css.keyword}>{viewAvailable}</span>
              {keywords.map((keyword, index) => (
                <NamedLink
                  key={index}
                  className={css.keyword}
                  name="SearchPage"
                  to={{ search: keyword?.search }}
                >
                  {keyword.name}
                </NamedLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHero;
